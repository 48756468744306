import moment from "moment";
import moment2 from "moment-timezone";
import API from "../../apis/api";
import * as htmlToImage from "html-to-image";
import { toast } from "react-toastify";
import PKRupeeIcon from "../../assets/icons/currency/PKRupeeIcon";
import { BiRupee } from "react-icons/bi";
import { TbCurrencyDirham } from "react-icons/tb";
import store from "../../redux/store";

export const ShowTotalEntriesCount = (
  number = 0,
  singleString,
  multiString
) => {
  return `${number} ${number > 1 ? multiString : singleString}`;
};

export const getLocationFromPinCode = async (value) => {
  const backend = new API();
  try {
    const response = await backend.fetch(API.getLocationFromPinCode(value), {
      headers: {
        Accept: "multipart/form-data",
        "access-control-allow-origin": "*",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "X-Logged-branch-ID": localStorage.getItem("branchId") || "undefined",
      },
    });

    const data = await response;
    if (data.data) {
      return data.data;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error fetching search results:", error);
  }
};

export const convertElementToImageX = (element) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const elementBounds = element.getBoundingClientRect();

    canvas.width = elementBounds.width;
    canvas.height = elementBounds.height;

    // Render the content of the element onto the canvas
    const renderElement = async () => {
      const clone = element.cloneNode(true);
      clone.style.position = "static"; // Ensure the cloned element doesn't interfere with the layout
      const html = new XMLSerializer().serializeToString(clone);
      const img = new Image();

      img.onload = () => {
        context.drawImage(img, 0, 0);
        const dataUrl = canvas.toDataURL("image/png"); // or 'image/jpeg'
        resolve(dataUrl);
      };

      img.onerror = (error) => {
        // reject(error);
      };

      img.src = "data:image/jpg;base64," + btoa(html);
      // console.log(img.src);
    };

    renderElement();
  });
};

export const convertElementToImage = (element) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const elementBounds = element.getBoundingClientRect();

    canvas.width = elementBounds.width;
    canvas.height = elementBounds.height;

    const computedStyle = window.getComputedStyle(element);

    // Draw the element onto the canvas
    const renderElement = (node) => {
      console.log(
        "nodeType",
        node,
        node.nodeType,
        node.nodeType === Node.TEXT_NODE,
        node.nodeType === Node.ELEMENT_NODE
      );
      if (node.nodeType === Node.TEXT_NODE) {
        context.fillStyle = computedStyle.color;
        context.font = computedStyle.font;
        context.fillText(node.textContent, 0, 0); // Draw text at origin (0, 0)
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        console.log("node ___>", window.getComputedStyle(node));
        const cssStyles = window.getComputedStyle(node);
        const rect = node.getBoundingClientRect();
        context.beginPath();
        context.rect(
          rect.left - elementBounds.left,
          rect.top - elementBounds.top,
          rect.width,
          rect.height
        );
        context.fillStyle = cssStyles.backgroundColor;
        context.fill();
        context.strokeStyle = cssStyles.borderColor;
        context.lineWidth = parseInt(cssStyles.borderWidth, 10);
        context.stroke();
      }

      node.childNodes.forEach(renderElement);
    };

    renderElement(element);

    // Once the element is drawn onto the canvas, resolve the promise with the data URL
    const dataUrl = canvas.toDataURL("image/png"); // or 'image/jpeg'
    resolve(dataUrl);
  });
};

export const downloadImage = async () => {
  const element = document.getElementById("template-element-id");

  try {
    const dataUrl = await htmlToImage.toPng(element);
    const img = new Image();
    img.src = dataUrl;
    console.log(img.src);
    return img.src;
    // document.body.appendChild(img);
  } catch (error) {
    console.error("Conversion failed:", error);
    toast.error("Conversion failed");
  }
  // htmlToImage
  //   .toCanvas(document.getElementById("template-element-id"))
  //   .then(function (canvas) {
  //     console.log(canvas);
  //     // document.body.appendChild(canvas);
  //   });

  // htmlToImage.toPixelData(document.getElementById("template-element-id")).then(function (pixels) {
  //   for (var y = 0; y < node.scrollHeight; ++y) {
  //     for (var x = 0; x < node.scrollWidth; ++x) {
  //       pixelAtXYOffset = 4 * y * node.scrollHeight + 4 * x;
  //       /* pixelAtXY is a Uint8Array[4] containing RGBA values of the pixel at (x, y) in the range 0..255 */
  //       pixelAtXY = pixels.slice(pixelAtXYOffset, pixelAtXYOffset + 4);
  //     }
  //   }
  // });
};

export const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, "0"); // Get day with leading zero if necessary
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get month with leading zero if necessary
  const year = date.getFullYear(); // Get full year
  return `${day}-${month}-${year}`; // Return formatted date string
};

export const formatTime = (time) => {
  let hours = time.getHours() % 12 || 12; // Get hours in 12-hour format
  const minutes = time.getMinutes().toString().padStart(2, "0"); // Get minutes with leading zero if necessary
  const period = time.getHours() >= 12 ? "PM" : "AM"; // Determine AM or PM

  // Ensure that hours less than 10 are formatted with leading zero
  hours = hours < 10 ? `0${hours}` : hours;

  return `${hours}:${minutes} ${period}`; // Return formatted time string
};

// Function to convert value to class name
export const getValueClassName = (value) => {
  switch (value) {
    case "booked":
      return "Booked";
    case "Canceled":
      return "Cancelled";
    case "cancel":
      return "Cancelled";
    case "no-show":
      return "noshow";
    case "completed":
      return "Completed";
    default:
      return ""; // Return empty string for unknown values
  }
};
// Function to convert value to status with capital letter
export const getValueStatusText = (value) => {
  switch (value) {
    case "booked":
      return "Booked";
    case "Canceled":
      return "Cancelled";
    case "no-show":
      return "No Show";
    case "completed":
      return "Completed";
    default:
      return ""; // Return empty string for unknown values
  }
};

export const getClassNameForClientTag = (value) => {
  const classNames = {
    Loyal: "Loyal",
    New: "New",
    NeedAttention: "Need-Attention",
    Lost: "Lost",
    AtRisk: "At-Risk",
    Manual: "Manual",
    Import: "Import",
    VIP: "Vip",
    Referred: "Referred",
  };

  return classNames[value] || "";
};

export const formatDateForDateSwiper = (date) => {
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "day").startOf("day");
  const tomorrow = moment().add(1, "day").startOf("day");
  const formattedDate = moment(date);

  if (formattedDate.isSame(today, "day")) {
    return "Today";
  } else if (formattedDate.isSame(yesterday, "day")) {
    return "Yesterday";
  } else if (formattedDate.isSame(tomorrow, "day")) {
    return "Tomorrow";
  } else {
    return formattedDate.format("DD-MM-YYYY");
  }
};

export const calculatePercentage = (value, total) => {
  if (typeof value !== "number" || typeof total !== "number" || total === 0) {
    return 0; // Avoid division by zero or invalid input
  }

  return (value / total) * 100;
};

export const renderSubServices = (services) => {
  let displayText = "";

  if (services.length > 0) {
    displayText = services[0].service_subService_name;

    if (services.length > 1) {
      displayText += `, ${services[1].service_subService_name}`;
    }

    if (services.length > 2) {
      displayText += ` + ${services.length - 2}`;
    }
  }

  return displayText;
};

// // Function to convert object to query string params
// export const objectToParams = (obj) => {
//   const params = [];
//   for (let key in obj) {
//     if (Array.isArray(obj[key])) {
//       // If it's an array, handle differently for paymentMode
//       if (key === "paymentMode") {
//         const values = obj[key].map((item) => item.value).join(",");
//         params.push(`${key}=${values}`);
//       } else {
//         const values = obj[key].map((item) => item.value).join("%2C");
//         params.push(`${key}=${values}`);
//       }
//     } else {
//       // Otherwise, directly append key-value pair
//       params.push(`${key}=${encodeURIComponent(obj[key])}`);
//     }
//   }
//   return params.join("&");
// };

// Function to convert object to query string params
export const objectToParams = (obj) => {
  const params = [];
  for (let key in obj) {
    if (Array.isArray(obj[key])) {
      // Handle array values (e.g., paymentMode)
      const values = obj[key].map((item) => item.value).join(",");
      params.push(`${key}=${values}`);
    } else {
      // Check if the value is a date in ISO format and bypass encoding for specific date keys
      if (
        (key === "start_date" || key === "end_date") &&
        /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(obj[key])
      ) {
        params.push(`${key}=${obj[key]}`);
      } else {
        // Otherwise, encode the value
        params.push(`${key}=${encodeURIComponent(obj[key])}`);
      }
    }
  }
  return params.join("&");
};

export const convertAnyDateFormatToDDMMYYYY = (dateString) => {
  // Return an empty string if the dateString is empty
  if (!dateString) {
    return "";
  }

  // Check if the date is already in 'DD-MM-YYYY' format
  if (moment(dateString, "DD-MM-YYYY", true)?.isValid()) {
    return dateString;
  }

  // Convert and format the date to 'DD-MM-YYYY'
  return moment(dateString)?.format("DD-MM-YYYY");
  // return dateString;
};

export const isObjectEmpty = (data) => {
  for (let key in data) {
    if (data.hasOwnProperty(key)) {
      return false;
    }
  }
  return true;
};

// function to get user timezone
export const getUserTimeZone = () => {
  // var userTimeZone = localStorage.getItem("userTimeZone")
  //   ? localStorage.getItem("userTimeZone")
  //   : moment2.tz.guess();
  const userTimeZone = moment2.tz.guess();
  localStorage.setItem("userTimeZone", userTimeZone || "UTC");
  // localStorage.setItem("userTimeZone", userTimeZone);
  // setUserTimeZone(userTimeZone || "UTC"); // Fallback to UTC if timezone cannot be determined
  return userTimeZone || "UTC";
};

// Function to convert only the appointment date to UTC (based on the user's timezone)
export const convertDateToUTC = (date) => {
  const userTimeZone = getUserTimeZone();
  // const utcDate = moment.tz(date, "DD-MM-YYYY", userTimeZone).utc().format("DD-MM-YYYY");
  const utcDate = moment2.tz(date, "DD-MM-YYYY", userTimeZone).utc();
  // return utcDate;  // Return only the date in UTC
  return utcDate.toISOString(); // Return only the date in ISO
};
// Function to convert appointment time to UTC, combining date and time, and returning the full UTC timestamp
// export const convertTimeToUTC = (date, time, userTimeZone) => {
//   const utcDateTime = moment2.tz(`${date} ${time}`, "DD-MM-YYYY hh:mm A", userTimeZone).utc().format();
//   return utcDateTime; // Return full UTC date-time in ISO 8601 format
// };
export const convertTimeToUTC = (date, time) => {
  const userTimeZone = getUserTimeZone();
  // Combine the date and time into a single string
  const combinedDateTime = `${date} ${time}`;
  // console.log("Combined DateTime:", combinedDateTime); // Debugging line

  // Parse the combined date and time in the user's timezone and convert it to UTC
  const utcTime = moment
    .tz(combinedDateTime, "DD-MM-YYYY hh:mm A", userTimeZone)
    .utc();

  if (!utcTime.isValid()) {
    console.error("Invalid Date for:", combinedDateTime); // Debugging line
    return null; // or handle the error as needed
  }

  return utcTime.toISOString(); // Return the UTC time in ISO format
};
// // Function to convert UTC time to user timezone
// export const convertUTCToUserTimeZone = (utcTime) => {
//   const userTimeZone = localStorage.getItem("userTimeZone") || "UTC";
//   return moment2.tz(utcTime, userTimeZone);
// };

// Function to convert UTC time or DD-MM-YYYY to user's timezone
export const convertUTCToUserTimeZone = (dateInput) => {
  const userTimeZone = localStorage.getItem("userTimeZone") || "UTC";

  // Check if dateInput is in ISO format or DD-MM-YYYY
  const isISOFormat = moment(dateInput, moment.ISO_8601, true).isValid();
  const isCustomFormat = moment(dateInput, "DD-MM-YYYY", true).isValid();

  let localTime;
  if (isISOFormat) {
    localTime = moment.tz(dateInput, userTimeZone); // Parse and convert ISO format to user timezone
  } else if (isCustomFormat) {
    // localTime = moment(dateInput, "DD-MM-YYYY").tz(userTimeZone); // Parse custom format
    localTime = moment(dateInput, "DD-MM-YYYY"); // Parse custom format
  } else {
    return moment.invalid(); // Return an invalid date if neither format matches
  }

  return localTime;
};

// Function to convert local time to UTC
export const convertLocalToUTC = (localDate) => {
  const userTimeZone = localStorage.getItem("userTimeZone") || "UTC";
  // return moment.tz(localDate, userTimeZone).utc().format("DD-MM-YYYY");
  return moment.tz(localDate, userTimeZone).utc();
};
// // Function to format the date in DD-MM-YYYY
// export const formatDateFromISO = (utcTime) => {
//   const localTime = convertUTCToUserTimeZone(utcTime);
//   return localTime.format("DD-MM-YYYY");
// };

// // Function to format the time in HH:MM A
// export const formatTimeFromISO = (utcTime) => {
//   const localTime = convertUTCToUserTimeZone(utcTime);
//   return localTime.format("hh:mm A");
// };

// Function to format date in DD-MM-YYYY
export const formatDateFromISO = (dateInput) => {
  const localTime = convertUTCToUserTimeZone(dateInput);
  return localTime.isValid() ? localTime.format("DD-MM-YYYY") : "Invalid Date";
};

// Function to format time in HH:MM A
export const formatTimeFromISO = (dateInput) => {
  const localTime = convertUTCToUserTimeZone(dateInput);
  return localTime.isValid() ? localTime.format("hh:mm A") : "Invalid Date";
};

// //////////////////////// CURRENCY FUNCTIONS /////////////////////////////

export const getCurrencySymbol = () => {
  const state = store.getState(); // Access the current state from Redux
  // console.log("state.currency.symbol:",state.currency.symbol);
  const currencySymbol = state.currency.symbol || "₹"; // Default to INR (₹) if no symbol is found
  return currencySymbol;
};
export const getCurrencyCode = () => {
  const state = store.getState(); // Access the current state from Redux
  // console.log("state.currency.code:",state.currency.currency.code);

  const currencyCode = state.currency.currency.code || "INR"; // Default to INR (₹) if no Code is found
  return currencyCode;
};

export const getCurrencySymbolIcon = () => {
  const symbol = getCurrencySymbol(); // Get currency symbol from context
  // const symbol = 'د.إ'; // Get currency symbol from context
  // console.log({symbol});

  switch (symbol) {
    case "₨":
      return <PKRupeeIcon />;
    case "₹":
      return <BiRupee />;
    case "د.إ":
      return <TbCurrencyDirham />;
    default:
      return symbol; // Fallback to text symbol if no match
  }
};

export const getCurrencySymbolTextforTd = (amount) => {
  const symbol = getCurrencySymbol(); // Get currency symbol from context
  //  const symbol = 'د.إ'; // Get currency symbol from context
  //  const symbol = '₹'; // Get currency symbol from context

  switch (symbol) {
    case "₨":
      return `${symbol} ${amount}`; // PKR Rupee (Left-to-Right)
    case "₹":
      return `${symbol} ${amount}`; // INR Rupee (Left-to-Right)
    case "د.إ":
      // return `${amount} ${symbol}`; // Dirham (Right-to-Left)
      return (
        <>
          <span className="rtl-currency">{symbol}</span> {amount}
        </>
      ); // Dirham (Right-to-Left)
    case "ر.ع.":
      return (
        <>
          <span className="rtl-currency">{symbol}</span> {amount}
        </>
      ); // OMR (Right-to-Left)
    default:
      return `${symbol} ${amount}`; // Default LTR behavior
  }
};
export const getCurrencySymbolText = () => {
  const symbol = getCurrencySymbol(); // Get currency symbol from context
  // const symbol = 'د.إ'; // Get currency symbol from context
  // return symbol;
  switch (symbol) {
    case "₨":
      return "₨";
    case "₹":
      return "₹";
    case "د.إ":
      return "د.إ";
    default:
      return symbol; // Fallback to text symbol if no match
  }
};

export const getCurrencySymbolTextForDropdown = () => {
  const currencyCode = getCurrencyCode(); // Get currency Code from context
  return currencyCode;
};

export const isLocalUser = () => {
  const symbol = getCurrencySymbol(); // Get currency symbol from context
  if (symbol === "₹") {
    return true;
  } else {
    return false;
  }
};

// Function to get tax options and rate based on the currency
export const getTaxOptionsAndRate = () => {
  const currency = getCurrencySymbol(); // Get currency symbol from context
  if (currency === "د.إ") {
    // UAE Dirham
    return {
      options: [
        { label: "0% VAT", value: "0" },
        { label: "5% VAT", value: "products services packages memberships" },
      ],
      rate: 5,
    };
  } else if (currency === "ر.ع.") {
    return {
      options: [
        { label: "0% VAT", value: "0" },
        { label: "5% VAT", value: "products services packages memberships" },
      ],
      rate: 5,
    };
  } else if (currency === "₨") {
    // Pakistani Rupee
    return {
      options: [
        { label: "0% GST", value: "0" },
        { label: "17% GST", value: "products services packages memberships" },
      ],
      rate: 17,
    };
  } else if (currency === "₹") {
    // Indian Rupee
    return {
      options: [
        { label: "0% GST", value: "0" },
        { label: "18% GST", value: "products services packages memberships" },
      ],
      rate: 18,
    };
  } else {
    // Default tax options for other currencies
    return {
      options: [{ label: "0% on overall", value: "0" }],
      rate: 0,
    };
  }
};

export const formatPhoneNumber = (phoneNumber) => {
  const currency = getCurrencySymbol(); // Get currency symbol from context
  // Remove any non-digit characters from the input
  const cleanedNumber = phoneNumber.replace(/\D/g, "");

  switch (currency) {
    case "₹": // Indian Rupee
      return cleanedNumber.replace(/(\d{5})(\d{5})/, "+91 $1-$2");
    case "د.إ": // UAE Dirham
      return cleanedNumber.replace(/(\d{2})(\d{3})(\d{4})/, "+971 $1-$2-$3");
    case "₨": // Pakistani Rupee
      return cleanedNumber.replace(/(\d{3})(\d{7})/, "+92 $1-$2");
    default:
      // Generic international format
      return cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, "+XX $1-$2-$3");
  }
};
export const getPhoneRegex = () => {
  const state = store.getState(); // Access the current state from Redux
  // console.log("Regex: ", state.currency.currency.phoneRegex);

  // Extract regex pattern and replacement string from the API response
  const phoneRegexString =
    state.currency.currency.phoneRegex || "/(\\d{5})(\\d{5})/,'+91 $1-$2'";

  // Split the pattern and replacement if stored as a single string
  const [pattern, replacement] = phoneRegexString
    .match(/\/(.+)\/, '(.*)'/)
    .slice(1, 3);

  // Return as an object containing the RegExp and replacement string
  return { regex: new RegExp(pattern), replacement };
};
export const formatPhoneNumberText = (phoneNumber) => {
  if (!phoneNumber) {
    // Return an empty string or a placeholder if phoneNumber is undefined or null
    return "";
  }
  // Remove any non-digit characters from the input
  const cleanedNumber = phoneNumber?.replace(/\D/g, "");

  // Get the regex and replacement string
  const { regex, replacement } = getPhoneRegex();

  // console.log(cleanedNumber.replace(/(\d{5})(\d{5})/, "+91 $1-$2"), regex, replacement);

  // Apply formatting using the regex and replacement
  // console.log({regex, replacement, phoneNumber,cleanedNumber});
  
  return cleanedNumber.replace(regex, replacement);
  // return "";
};
