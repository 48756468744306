// import React, { useState } from "react";
// import BarcodeScannerComponent from "react-qr-barcode-scanner";

// const BarcodeScanner = () => {
//   const [data, setData] = useState("No result");

//   return (
//     <div>
//       <h3>Scan a Barcode</h3>
//       <BarcodeScannerComponent
//         width={300}
//         height={300}
//         onUpdate={(err, result) => {
//           if (result) setData(result.text);
//           else setData("No result");
//         }}
//       />
//       <p>Scanned Data: {data}</p>
//     </div>
//   );
// };

// export default BarcodeScanner;




//////////////////////////////////////////////////////////////////////////////
import React, { useRef, useState, useEffect } from "react";
import { BrowserMultiFormatReader } from "@zxing/browser";

const BarcodeScanner = () => {
  const [result, setResult] = useState("");
  const videoRef = useRef(null);
  const codeReaderRef = useRef(null);

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader();
    codeReaderRef.current = codeReader;

    // Start scanning from the default camera
    codeReader
      .decodeFromVideoDevice(
        null, // Pass null to use the default camera
        videoRef.current,
        (result, error) => {
          if (result) {
            setResult(result.text); // Set the scanned result
          }
          if (error) {
            console.warn("Error detecting barcode:", error.message); // Log warnings
          }
        }
      )
      .catch((err) => {
        console.error("Error initializing barcode scanner:", err);
      });

    // Cleanup on component unmount
    return () => {
      if (codeReaderRef.current) {
        codeReaderRef.current.reset(); // Stops the scanner and releases the camera
      }
    };
  }, []);

  return (
    <div>
      <h3>Barcode Scanner</h3>
      <video ref={videoRef} style={{ width: "300px", height: "300px" }} />
      <p>Scanned Result: {result || "No barcode detected yet."}</p>
    </div>
  );
};

export default BarcodeScanner;
