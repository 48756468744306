import { createSlice } from '@reduxjs/toolkit';

// Function to get currency from localStorage or default to INR
const getInitialCurrency = () => {
  const storedCurrency = localStorage.getItem('currency');
  
  // Check if storedCurrency exists and is valid
  if (storedCurrency) {
    try {
      return JSON.parse(storedCurrency); // Attempt to parse JSON
    } catch (error) {
      console.error('Failed to parse stored currency:', error);
    }
  }

  // Default to INR if no valid data in localStorage
  return { name: 'Indian Rupee', symbol: '₹' , code : 'INR'};
};

const initialState = {
  currency: getInitialCurrency(),
  symbol: getInitialCurrency().symbol,
};

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    setCurrency: (state, action) => {
      const currencyData = action.payload;
      const newCurrencyData = {name: currencyData?.currency, symbol: currencyData?.currencySymbol , code: currencyData?.currencyCode ,phoneRegex: currencyData?.phoneRegex}
      state.currency = newCurrencyData;
      state.symbol = newCurrencyData?.symbol || null;
      // Save to localStorage for future sessions
      localStorage.setItem('currency', JSON.stringify(newCurrencyData));
    },
  },
});

export const { setCurrency } = currencySlice.actions;

export default currencySlice.reducer;