import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import { Suspense, lazy, useEffect } from "react";
import RecordExpense from "./pages/expense/RecordExpense";
import ExpensesList from "./pages/expense/ExpensesList";
import ProfileRedirect from "./pages/miniWebsite/ProfileRedirect";
import EditSale from "./pages/sales/EditSale";
import AddOperatorPermissions from "./pages/staff/AddOperatorPermissions";
import EditOperatorPermissions from "./pages/staff/EditOperatorPermissions";
import StaffLogin from "./pages/staffLogin";
import API from "./apis/api";
import { setModulesPermissions } from "./redux/authSession/authSession";
import { useDispatch, useSelector } from "react-redux";
import { isObjectEmpty } from "./utils/commonFunctions";
import BarcodeScanner from "./pages/Barcode";
const Login = lazy(() => import("./pages/login"));
const ProductList = lazy(() => import("./pages/Inventory/productList"));
const AddProduct = lazy(() => import("./pages/Inventory/addProduct"));
const Insights = lazy(() => import("./pages/Inventory/insights"));
const CreateCampaign = lazy(() => import("./pages/marketing/createCampaign"));
const CreateOfferCampaign = lazy(() => import("./pages/marketing/createOfferCampaign"));
const PlaceholderPage = lazy(() => import("./pages/PlaceHolderPage"));
const RecordSale = lazy(() => import("./pages/sales/RecordSale"));
const SalesEntries = lazy(() => import("./pages/sales/SalesEntries"));
const Calendar = lazy(() => import("./pages/appointment/calendar"));
const Memberships = lazy(() => import("./pages/membership/memberships"));
const Packages = lazy(() => import("./pages/membership/packages"));
const BookAppointmnet = lazy(() => import("./pages/appointment/BookAppointmnet"));
const AllAppointmnets = lazy(() => import("./pages/appointment/AllAppointmnets"));
const AddClient = lazy(() => import("./pages/client/AddClient"));
const Clientlist = lazy(() => import("./pages/client/ClientList"));
const AddEnquiry = lazy(() => import("./pages/enquiry/AddEnquiry"));
const EnquiryList = lazy(() => import("./pages/enquiry/EnquiryList"));
const AddStaff = lazy(() => import("./pages/staff/AddStaff"));
const StaffList = lazy(() => import("./pages/staff/StaffList"));
const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Reports = lazy(() => import("./pages/dashboard/Reports"));
const Insight = lazy(() => import("./pages/dashboard/Insight"));
const Profile = lazy(() => import("./pages/miniWebsite/Profile"));
const BookAppointment = lazy(() => import("./pages/miniWebsite/BookAppointment"));
const PageLoader = lazy(() => import("./component/commonLayout/PageLoader"));
const BulkUploadProduct = lazy(() => import("./pages/Inventory/addProduct/bulkUploadProduct"));

const useFetchModulePermissions = () => {
  const dispatch = useDispatch();
  const modulesPermissions = useSelector((state) => state?.authSession?.modulesPermissions);

  useEffect(() => {
    const fetchPermissions = async () => {
      const backend = new API();
      const permissionResponse = await backend.fetch(
        API.getStaffPermission(localStorage.getItem("staffId")),
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "X-Logged-branch-ID":
              localStorage.getItem("branchId") || "undefined",
          },
        }
      );

      if (permissionResponse.status === true) {
        console.log({ permissionResponse });
        dispatch(
          setModulesPermissions(permissionResponse?.data?.permissions)
        );
      }
    };

    if (localStorage.getItem("token") && localStorage.getItem("staffId") && isObjectEmpty(modulesPermissions)) {
      fetchPermissions();
    }
  }, [dispatch]);
};

function PrivateRoute({ component: Component, ...rest }) {
  const isAuthenticated = localStorage.getItem("token");
  // const isAuthenticated = true;
  useFetchModulePermissions(); // Use the custom hook
  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}
const App = () => {
  return (
    // <MyComponent />
    <Router>
       <Suspense fallback={ <PageLoader />}>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/barcode" element={<BarcodeScanner />} />
        <Route exact path="/staffLogin" element={<StaffLogin />} />
        {/* <Route exact path="/profile/:id" element={<Profile />} /> */}
        <Route exact path="/profile/:id" element={<ProfileRedirect />} />
        {/* <Route exact path="/bookAppointment" element={<BookAppointment />} /> */}
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<CreateCampaign />} />
          {/* <Route exact path="/productList" element={<ProductList />} /> */}
          <Route exact path="/dashboard" element={<PrivateRoute />}>
            <Route exact path="" element={<Dashboard />} />
            {/* <Route exact path="reports" element={<PlaceholderPage />} /> */}
            <Route exact path="reports" element={<Reports />} />
            {/* <Route exact path="insights" element={<PlaceholderPage />} /> */}
            <Route exact path="insights" element={<Insight />} />
          </Route>
          <Route exact path="/sales" element={<PrivateRoute />}>
            <Route exact path="" element={<RecordSale />} />
            <Route exact path="record" element={<RecordSale />} />
            <Route exact path="entries" element={<SalesEntries />} />
            <Route exact path="insight" element={<PlaceholderPage />} />
            <Route exact path="editInvoice/:id" element={<EditSale />} />
          </Route>
          <Route exact path="/marketing" element={<PrivateRoute />}>
            <Route exact path="" element={<CreateCampaign />} />
            <Route exact path="campaigns" element={<CreateCampaign />} />
            <Route exact path="socialmedia" element={<PlaceholderPage />} />
            <Route exact path="insight" element={<PlaceholderPage />} />
            <Route exact path="create" element={<CreateOfferCampaign />} />
            <Route
              exact
              path="campaigns/create"
              element={<CreateOfferCampaign />}
            />
          </Route>
          <Route exact path="/client" element={<PrivateRoute />}>
            <Route exact path="" element={<Clientlist />} />
            <Route exact path="addclient" element={<AddClient />} />
            <Route exact path="clientlist" element={<Clientlist />} />
          </Route>
          <Route exact path="/enquiry" element={<PrivateRoute />}>
            <Route exact path="" element={<EnquiryList />} />
            <Route exact path="addenquiry" element={<AddEnquiry />} />
            <Route exact path="enquirylist" element={<EnquiryList />} />
          </Route>
          <Route exact path="/inventory" element={<PrivateRoute />}>
            <Route exact path="" element={<ProductList />} />
            <Route exact path="addproduct" element={<AddProduct />} />
            <Route exact path="productlist" element={<ProductList />} />
            <Route exact path="importProducts" element={<BulkUploadProduct />} />
            <Route exact path="insight" element={<Insights />} />
          </Route>
          <Route exact path="/settings" element={<PrivateRoute />}>
            <Route exact path="" element={<PlaceholderPage />} />
          </Route>
          <Route exact path="/staff" element={<PrivateRoute />}>
            <Route exact path="" element={<AddStaff />} />
            <Route exact path="addstaff" element={<AddStaff />} />
            <Route exact path="stafflist" element={<StaffList />} />
            <Route exact path="SetPermissions/:id" element={<AddOperatorPermissions />} />
            <Route exact path="EditPermissions/:id" element={<EditOperatorPermissions />} />
          </Route>
          <Route exact path="/expense" element={<PrivateRoute />}>
            <Route exact path="" element={<RecordExpense />} />
            <Route exact path="recordExpense" element={<RecordExpense />} />
            <Route exact path="expenses" element={<ExpensesList />} />
            <Route exact path="expensesReport" element={<PlaceholderPage />} />
          </Route>
          <Route exact path="/appointments" element={<PrivateRoute />}>
            <Route exact path="" element={<BookAppointmnet />} />
            <Route exact path="book" element={<BookAppointmnet />} />
            <Route exact path="allappointments" element={<AllAppointmnets />} />
            <Route exact path="calendar" element={<Calendar />} />
          </Route>
          <Route exact path="/catalogue" element={<PrivateRoute />}>
            <Route exact path="" element={<Memberships />} />
            <Route exact path="services" element={<PlaceholderPage />} />
            <Route exact path="packages" element={<Packages />} />
            <Route exact path="memberships" element={<Memberships />} />
            <Route exact path="loyaltypoints" element={<PlaceholderPage />} />
            <Route exact path="gallery" element={<PlaceholderPage />} />
          </Route>
        </Route>
      </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
