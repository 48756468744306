import { createSlice } from "@reduxjs/toolkit";
import {
  clientAdd,
  clientList,
  clientDetailesEdit,
  walletList,
  addBalanceApi,
} from "./index";
import moment from "moment";

const clientSlice = createSlice({
  name: "clientSlice",
  initialState: {
    listClient: {},
    listClientIsLoading: false,
    clientListLoadedOn : "",
    editClient: {},
    editClientIsLoading: false,
    addClient: {},
    addClientIsLoading: false,
    listWallet: {},
    listWalletIsLoading: false,
    addBalance: {},
    addBalanceIsLoading: false,
    error: null,
  },
  reducers: {
    clearAddClient: (state) => {
      state.addClient = {};
    },
    clearClientDetailesEdit: (state) => {
      state.editClient = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clientAdd.pending, (state) => {
        state.addClientIsLoading = true;
      })
      .addCase(clientAdd.fulfilled, (state, action) => {
        state.addClient = action.payload;
        state.addClientIsLoading = false;
      })
      .addCase(clientAdd.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.addClientIsLoading = false;
      })
      .addCase(clientList.pending, (state) => {
        state.listClientIsLoading = true;
      })
      .addCase(clientList.fulfilled, (state, action) => {
        state.listClient[action.payload.tag] = action.payload;
        state.listClientIsLoading = false;
        state.clientListLoadedOn = moment();
      })
      .addCase(clientList.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.listClientIsLoading = false;
      })
      .addCase(clientDetailesEdit.pending, (state) => {
        state.editClientIsLoading = true;
      })
      .addCase(clientDetailesEdit.fulfilled, (state, action) => {
        state.editClient = action.payload;
        state.editClientIsLoading = false;
      })
      .addCase(clientDetailesEdit.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.editClientIsLoading = false;
      })
      .addCase(walletList.pending, (state) => {
        state.listWalletIsLoading = true;
      })
      .addCase(walletList.fulfilled, (state, action) => {
        state.listWallet = action.payload;
        state.listWalletIsLoading = false;
      })
      .addCase(walletList.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.listWalletIsLoading = false;
      })
      .addCase(addBalanceApi.pending, (state) => {
        state.addBalanceIsLoading = true;
      })
      .addCase(addBalanceApi.fulfilled, (state, action) => {
        state.addBalance = action.payload;
        state.addBalanceIsLoading = false;
      })
      .addCase(addBalanceApi.rejected, (state, action) => {
        state.error = action.payload; // Make sure to handle the rejection correctly
        state.addBalanceIsLoading = false;
      });
  },
});
export const { clearAddClient, clearClientDetailesEdit } = clientSlice.actions;
export default clientSlice.reducer;
